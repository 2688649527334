import { SET_INVOICES, SET_INVOICES_LOADING, UPDATE_INVOICE, DELETE_INVOICE } from "../constants/action-types";
import SortingData from "../../helpers/SortingData";

const initialState = {
    loading: false,
    invoices: []
}
const sortByDate = new SortingData();



const invoicesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_INVOICES : {
            let invoices = action.payload;
            return {
                ...state,
                invoices: sortByDate.sortingByDate(invoices),
            }
        }

        case SET_INVOICES_LOADING : {
            let loading = action.payload;
            return  {
                ...state,
                loading
            }
        }

        case UPDATE_INVOICE : {
            let inv = state.invoices;
            let index = inv.findIndex(element => element.id === action.payload.id);
            if(index >= 0) {
                inv[index] = action.payload
                const sortedData = sortByDate.sortingByDate(inv);
                return {
                    ...state,
                    invoices: [ ...sortedData ],
                }
            }
            break;
        }

        case DELETE_INVOICE : {
            let invoices = state.invoices;
            let index = invoices.findIndex(element => element.id === action.payload);
            if(index >= 0) {
                invoices.splice(index, 1);
                const sortedData = sortByDate.sortingByDate(invoices);
                return {
                    ...state,
                    invoices: [ ...sortedData ],
                }
            }
            break;
        }
            
        default: return state;

    }
}

export default invoicesReducer;