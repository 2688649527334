import React, {Component} from 'react';
import Countries from 'country-data';
import { numberFormat } from '../../helpers/number';
import moment from 'moment'
import DateItemList from '../../Components/DateItemList';
import categories from '../../assets/svg/icon-masque.svg';
import attach from '../../assets/svg/icon-attach.svg';
import Tag from '../Tag';
import iconTag from '../AdminPreview/img/tag.svg';
import './TableData.css';
import Loader from '../Loader/Loader';


class TableData extends Component {
    constructor(props){
        super(props)

        this.state = {
            datas: this.props.datas,
            className: this.props.className,
            open: false,
            value: null,
            loading: this.props.loading
        }
       
    }

    getDayMonth = (fullDate) => {
        let part = fullDate.split('/')
        const month = part[1]
        const day = part[2]

        return {month,day}
    }

    onSelectItem = (item) => {
        this.props.onItemSelected(item)
    }

    getCurrencyByCountryName = (countryName) => {
        const currency = Countries.countries.all.find((element) => {return element.name === countryName;}) 
        return currency.currencies[0];
    }
    
    loadDatas = () => {
        const {datas} = this.props
        if(datas && datas.length > 0) {
            return (
                datas.map((item, index) =>(
                    <tr onClick={this.onSelectItem.bind(this, item)} value={index} key={index} >
                        <td className="text-center-h pr-3"><center> <DateItemList date={ (item.date) ? moment(item.date).format('YYYY/MM/DD') : moment(item.createdAt).format('YYYY/MM/DD')} active={item.date !== null}  /> </center></td>
                        <td className="text-center-h pr-3"> {/*{ item.categoryId !== undefined ? this.loadIconCategory(item.categoryId) : ""  }   <img src={item.categoryIcon} alt="categoryIcon" width={17} height={'auto'}/>{ (item.status !== 'pending' && item.status !== 'archived') ? item.category : ''} --- {`${this.getCurrencyByCountryName(item.organisationId.country)}`}  */}</td>
                        <td className="font-size-table-data pr-3">{item.paymentType}</td>
                        <td className="deliver-style pr-3">{ item.deliver ? item.deliver : '' }</td>
                        <td className="text-left-h-description pr-3"><div className='d-flex'><Tag icon={iconTag} string={ item.description ? '' : item.description } /></div></td>
                        <td className="font-size-table-data pr-3">{ item.nui }</td>
                        <td className="text-right-h font-size-table-data pr-3">{(item.netAmount) ? (<div><span className="devise"> {(item.hasOwnProperty('currency')) ? item.currency : null } </span> {numberFormat(item.netAmount)} </div>) : ''}</td>
                        <td className="text-right-h font-size-table-data pr-3">{(!item.vat) ? '' : (<div><span className="devise"> {(item.hasOwnProperty('currency')) ? item.currency : null } </span> {numberFormat(item.vat)} </div>) }</td>
                        <td className="text-right-h font-size-table-data pr-3">{(item.totalAmount) ? (<div><span className="devise"> {(item.hasOwnProperty('currency')) ? item.currency : null } </span> {numberFormat(item.totalAmount)} </div>) : ''}</td>
                        <td className="text-center-h font-size-table-data pr-3"><img src={attach} alt="Attach" width={20} height={'auto'} /></td>
                    </tr>
                ))
            )
        }
        return (
            <tr>
                <td colSpan={9} className="text-center-h">NO RESULT</td>
            </tr>
        )
    }

    render(){
        const {className} = this.props
        // let modal = this.state.open ? <ModalData open={this.state.open} value={this.state.value} onCloseModal={this.onCloseModal} onDataUpdated={this.onDataUpdated}/> : <></>
        return (
            <>
            <table className={"table-data " + className}>
                <thead>
                    <tr>
                        <th width={60} className="text-center-h pr-3">Date</th>
                        <th width={60} className="text-center-h pr-3"><img src={categories} alt="Categories" width={20} height={'auto'} /></th>
                        <th className="pr-3">Type de compte</th>
                        <th width={'15%'} className='pr-3'>Fournisseur</th>
                        <th className="text-left-h pr-3">Description</th>
                        <th width={'12%'}>NUI</th>
                        <th width={'12%'} className="text-right-h pr-3" >Montant HT</th>
                        <th width={'12%'} className="text-right-h pr-3" >TVA</th>
                        <th width={'12%'} className="text-right-h pr-3">Montant total</th>
                        <th width={50} className="text-center-h pr-3"><img src={attach} alt="Attach" width={20} height={'auto'} /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        !this.props.loading ? this.loadDatas() : null
                    }
                </tbody>
            </table>
            <div className={this.props.loading ? 'd-flex justify-content-center' : 'd-none'}>
                    <Loader loading={this.props.loading} />
            </div>
            </>
        )
    }
}

export default TableData