import React, {Component} from 'react'

import './Loader.css'

class Loader extends Component {

    constructor(props){
        super(props) 

        this.state = {
            enable: false,
        }
        this.toggleLoad = this.toggleLoad.bind(this)
    }

    toggleLoad = () => {
        this.setState({
            enable: !this.state.enable
        })
    }

    // disable = () => {
    //     this.setState({
    //         enable: false
    //     })
    // }

    render(){
        const {enable} = this.state
        return(
            <div className={(this.props.loading || enable) ? 'loader visible' : 'loader'}>
                <span className="half-circle"></span>
                <span className="plain-circle"></span>
            </div>
        )
    }
}

export default Loader