import React from 'react';
import Error from '../pages/Error';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { ROUTES } from "../Constants/Routes";
import { _checkAuth } from '../services/auth.service';


export const AuthRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        _checkAuth() ? (
            <Component {...props} />
        ) : (
                <Redirect to={"/"} />
            )
    )} />
)

export default function Routes() {
    // Authenticated routes guard
;    return (
        <BrowserRouter>
            <Switch>
                { ROUTES.map((route, i) => {
                    return route.protected ?
                        <AuthRoute key={i} exact path={route.path} component={route.component} /> :
                        <Route key={i} exact path={route.path} component={route.component} />
                })}
                <Route path="*" component={Error} />
            </Switch>
        </BrowserRouter>

    )
}
