import React, {Component} from 'react'
import './Search.css'

class Search extends Component {
    constructor(props){
        super(props)

        this.state = {
            term: ''
        }
        this.searchHandler = this.searchHandler.bind(this)
    }

    searchHandler(event){
        // this.setState({term : event.target.value})
        // console.log("the element is: ", event)
        let value = event.target.value
        this.props.handleSearch(value.trim() !== '' ? value : '')
      }

    render(){
        const {term} = this.props
        return (
            <div className="ct-search">
                <form>
                    <input onChange={this.searchHandler} value={term} className="search" type="text" placeholder="Recherche"/>
                </form>
            </div>
        )
    }
}

export default Search