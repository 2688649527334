import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './NavItem.css'
class NavItem extends Component {
    constructor(props){
        super(props)

        this.state = {
            image: this.props.data.image,
            label: this.props.data.label,
            route: this.props.data.route,
            activClass: this.props.data.activClass,
            active: this.props.data.active,
            index: this.props.index
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = () => {
        const {index} = this.state
        const {onClick} = this.props

        onClick(index)
    }

    render(){
        const {image,label,activClass,active,route} = this.state
        return (
            <Link style={{ textDecoration: 'none' }} to={route}>
            <button className={(active) ? 'NavItem ' + activClass : 'NavItem '}  onClick={this.handleClick} >
                <div className="content-btn-item">
                    <img src={image} alt={label} style={{transform: "scale(0.8)"}} />
                    <span className="label">{label}</span>
                    <span className={(active) ? 'h-bar ' + activClass : 'h-bar '} ></span>
                </div>
            </button>
            </Link>
        )
    }
}

NavItem.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default NavItem