import React, {Component} from 'react'
import './TitleFeature.css'

class TitleFeature extends Component {
    constructor(props){
        super(props)

        this.state = {
            title: this.props.title,
            className: this.props.className
        }
    }

    render(){
        const {title,className} = this.state
        return (
            <div className={'title-feature ' + className}>
                <h4 className="h-title mt-20">{title}</h4>
            </div>
                
        )
    }
}

export default TitleFeature