export const toFloat = (value) => {
    let res = Number(Number.parseFloat(value).toFixed(2));
    if(Number.isNaN(res)){
        return 0;
    }else{
        return res;
    }
}

export const arrayUniq = (tab) => {
    return tab.filter((v,i) => tab.indexOf(v) === i);
}