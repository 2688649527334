class SortingData {
    sortingByDate = (data) => {
        // console.log('data unsorted: ', data)
    let date = data.filter((elem) => elem.date === null) 

        date.sort(function(a, b){
            if(a.createdAt > b.createdAt) { return -1; }
            if(a.createdAt < b.createdAt) { return 1; }
            return 0;
        })

        let others = data.filter((elem) => elem.date !== null) 

        others.sort(function(a, b){
            if(a.date > b.date) { return -1; }
            if(a.date < b.date) { return 1; }
            return 0;
        })

        return date.concat(others)
    }

    alphabeticSort = (data) => {
        data.sort((a, b) => {
            if(a > b) { return 1; }
            if(a < b) { return -1; }
            return 0;
        })
        return data;
    }
}

export default SortingData