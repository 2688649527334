import { SET_AUTH_DATA, SET_CURRENT_AUTH, SET_AUTH_TOKEN, GET_ROLE } from '../action-type/authType'

export const setAuthData = (authData) => ({
    type: SET_AUTH_DATA,
    payload: authData
})

export const setCurrentAuthUser = (currentAuth) => ({
    type: SET_CURRENT_AUTH,
    payload: currentAuth
})

export const setAuthToken = (authToken) => ({
    type: SET_AUTH_TOKEN,
    payload: authToken
})

export const getRole = (accessToken, role) => ({
    type: GET_ROLE,
    payload: {token: accessToken, role: role}
})
