export const SESSION_STORAGE_KEYS = {
    TOKEN: "token",
    ROLE: "role",
    HABILITATIONS: "habilitations",
    HABILITATIONS_LOADED: "habilitationsLoaded",
}

export const PATHS = {
    UNAUTHORIZED_PATH: "/",
    AUTH_FAILED_URL: "/",
}

export const ROLES = {
    ADMIN : "admin",

}

export const FUNCTIONALITIES_CODES = {
    DELETE_INVOICE: "INVOICES_DELETE",
}

export const ContentTypes = {
    pdf: "application/pdf",
    jpg: "image/jpg",
    png: "image/png",
    jpeg: "image/jpeg",
  }
  