import React from 'react'

import './Tag.css'

const Tag = ({string, icon}) => {
    if(string){
        let tags = string.split(',');
        return (
            tags.map((elem, index) => (
                <span className="tag-description" key={index}> <img src={icon} alt="user icon" width={12} height={12}/> 
                    <span className="tag-title">{elem}</span> </span>
            ))
        )
    } else {
        return <span/>
    }
};

export default Tag

