import React, {Component} from 'react'

import './Error.css'

class Error extends Component {
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){

        return (
            <div className="page-404">
                Error: Path does not exist!!!
            </div>
        )
    }
}

export default Error