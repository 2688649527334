import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import Loader from '../../Components/Loader'
import './Auth.css'
import { _authenticate } from '../../services/auth.service';
import { SESSION_STORAGE_KEYS } from '../../Constants/Constants';
import { Redirect } from "react-router-dom";

class Auth extends Component {
    constructor(props){
        super(props)
        this.state = {
            login: '',
            password: '',
            remember: false,
            error: null
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        sessionStorage.clear();
        this.setState({
            error: null
        })
        this.refs.loader.toggleLoad();
        const { login, password } = this.state
        _authenticate({username: login, password}).then(result => {
            //set the result into the store as auth token
            const access_token = result && result.access_token
            if(!result){
                this.setState({ redirect: "/" });
            }
            if(access_token) {
                sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, JSON.stringify(result));
                this.setState({ redirect: "/backlog" });
            } else {
                this.setState({
                    error: 'Nom d\'utilisateur ou mot de passe incorrect.'
                })
            }
            if(this.refs.loader)
                this.refs.loader.toggleLoad();
            
        }).catch(err => {
            console.error('error', err);
            const response = err.request && err.request.response
            if(response) {
                let errorResponse = JSON.parse(response);
                console.error('Error response', errorResponse);
                if(errorResponse.message && errorResponse.message.includes('Invalid user credentials')) {
                    this.setState({
                        error: 'Nom d\'utilisateur ou mot de passe incorrect.'
                    })
                }
            }
            if(this.refs.loader)
                this.refs.loader.toggleLoad();
        })
    }

    loadError = () => {
        let { error } = this.state
        return error != null ? (
            <Form.Text className="text-muted">
                <span style={{color: "red"}} > {error} </span>
            </Form.Text>
        ) : <div></div>
    }

    render(){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
          }
        return (
            <div className="auth">
                <div className="box-auth">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                        <h3>Dizen</h3>
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Login</Form.Label>
                        <Form.Control type="text" name="login" onChange={this.handleChange} placeholder="Votre login" />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" name="password" onChange={this.handleChange} placeholder="Votre mot de passe" />
                    </Form.Group>
                    <Form.Group controlId="formBasicChecbox">
                        <Form.Check type="checkbox" label="Se souvenir de moi" />
                    </Form.Group>
                    <Form.Group>
                        {
                            this.loadError()
                        }
                    </Form.Group>
                    <div className="bottom d-flex align-items-center justify-content-between">
                        <Button variant="primary" type="submit" className="h-40">
                            Se connecter
                        </Button>
                        <Loader ref="loader" />
                    </div>
                </Form>
                </div>
            </div>
        )
    }
}

export default Auth
