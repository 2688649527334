import { SET_INVOICES, SET_INVOICES_LOADING, UPDATE_INVOICE, DELETE_INVOICE } from "../constants/action-types";

export const setInvoices = (payload) => {
    return {
        type: SET_INVOICES,
        payload
    }
}

export const setInvoicesLoading = (payload) => {
    return {
        type: SET_INVOICES_LOADING,
        payload
    }
}

export const updateInvoice = (payload) => {
    return {
        type: UPDATE_INVOICE,
        payload
    }
}

export const deleteInvoice = (id) => {
    return {
        type: DELETE_INVOICE,
        payload: id,
    }
}