import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Countries from 'country-data';
import {toFloat, arrayUniq} from '../../helpers/dataFormat';
import SortingData from '../../helpers/SortingData';
import iconSave from '../../assets/svg/icon-save.svg';
import iconTrash from '../../assets/svg/iconTrash.svg';
import logoUser from '../../assets/svg/mboatech.svg';
import iconTag from './img/tag.svg';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import DatePicker from "react-date-picker";
import {updateInvoices, getInvoiceById, _deleteInvoice} from '../../services/invoice.service';
import ItemsCategory from '../../Constants/DataCategory';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import CategoryTheme from './CategoryTheme';
import { updateInvoice, deleteInvoice } from '../../store/actions/invoicesActions';
import { ContentTypes } from '../../Constants/Constants';
import Tag from '../Tag/Tag';
import { numberFormat } from '../../helpers/number';
import { _canAccess } from '../../services/auth.service';
import { FUNCTIONALITIES_CODES } from '../../Constants/Constants';

import './AdminPreview.css';

    // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function getSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }

        const regex = new RegExp('\\b' + escapedValue, 'i');

        return ItemsCategory.filter(category => regex.test(getSuggestionValue(category)));
    }

    function getSuggestionValue(suggestion) {
        return `${suggestion.title}`;
    }

    function renderSuggestion(suggestion, { query }) {
        const suggestionText = `${suggestion.title}`;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <span className={'suggestion-content ' + suggestion.image}>
                <span className="name">
                    {
                    parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;

                        return (
                        <span className={className} key={index}>{part.text}</span>
                        );
                    })
                    }
                </span>
            </span>
        );
    }
  
class AdminPreview extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            loading: false,
            category: '',
            ...this.setData(this.props.dataValues),
            value: '',
            suggestions: []
        };
        this.onChangeNetAmmount= this.onChangeNetAmmount.bind(this)

        this.onChangeVat= this.onChangeVat.bind(this)
        
        this.handleChange = this.handleChange.bind(this);
    }

    setData(invoice){
        return {
            id: invoice.id,
            currency: this.setDefaultCurrency(invoice.currency),
            deliver: invoice.deliver,
            nui: invoice.nui,
            totalAmount: invoice.totalAmount,
            attachment: invoice.attachment,
            vat: invoice.vat,
            netAmount: invoice.netAmount,
            date: invoice.date ? invoice.date : invoice.createdAt,
            createdAt: invoice.createdAt,
            paymentType: invoice.paymentType,
            description: invoice.description,
            startDate: new Date(),
            contentType: invoice.contentType,
        }
    }

    UNSAFE_componentWillMount(){
        this.processInvoice();
    }

    processInvoice = async () => {
        const {id} = this.props.dataValues;
        
        this.setState({
            loading: true,
        });
        const invoice = await getInvoiceById(id);
        this.setState({
            ...this.setData(invoice),
            loading: false
        });
    }

    setDefaultCurrency = (currency) => { 
        if(currency !== null && currency !== ""){
            return currency
        } else {
            const defaultCurrency = Countries.countries.all.find((element) => {return element.name === this.props.dataValues.organizationCountry;})            
            return defaultCurrency ? defaultCurrency.currencies[0]: Countries.countries.all[0].currencies[0];
        }
    }
    onChange = (event, { newValue, method }) => {
        this.setState({
          value: newValue
        });
    };
      
    onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
        suggestions: getSuggestions(value)
    });
    };
    
    onSuggestionsClearRequested = () => {
    this.setState({
        suggestions: []
    });
    };

    componentDidMount(){
        this.setState({
            totalAmount: this.state.netAmount + this.state.vat
        }) 
    }

    handleChange = (date) => {
        this.setState({
          startDate: date,
          date: moment(date).format('YYYY/MM/DD'),
        });
    }

    onChangeNetAmmount(e) {
        const netAmount = toFloat(e.target.value);
        this.setState({
            netAmount,
            totalAmount: numberFormat(toFloat(netAmount + toFloat( this.state.vat )), 2)
        });
    }

    onChangeVat(e) {
        const vat = toFloat(e.target.value);
        this.setState({
            vat,
            totalAmount: numberFormat(toFloat(vat + toFloat( this.state.netAmount )), 2)
        });
    }
    
    dataChange({target}){
        this.setState({
            [target.name]: target.value
        })
    }

    deleteInvoice = () => {
        this.setState({
            loading: true 
        });
        _deleteInvoice(this.props.dataValues.id).then((res) => {
            this.resetData();
            this.props.deleteInvoice(this.props.dataValues.id);
            this.props.onDataUpdated();
            this.displayNotification('Facture supprimée avec succès', 'success');
        }).catch((err) => {
            this.setState({
                loading: false
            });
            this.displayNotification( 'Echec de la suppression, veuillez reéssayer ultérieurement !', 'error');
        })
    }

    displayNotification = (message, type) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        });
            
        Toast.fire({
            customClass:{
                title: 'swal-custom-notif'
            },
            type: type || "success",
            title: message
        })
    }
    resetData = () => {
        this.setState({
            ...this.state,
            loading: false,
            category: '',
            deliver: '',
            nui: '',
            totalAmount: '',
            vat: '',
            netAmount: '',
            paymentType: '',
            currency: ''
        })
    }

    postData(event){
        event.stopPropagation();
        event.preventDefault()

        const {deliver,nui,totalAmount,vat,netAmount,paymentType,date,currency} = this.state

        this.setState({ loading: true });
        
        const invoiceToUpdate = {
            id: this.props.dataValues.id,
            deliver: deliver,
            nui: nui,
            totalAmount: totalAmount? Number.parseFloat(totalAmount): 0,
            vat:  vat? Number.parseFloat(vat): 0,
            netAmount:  netAmount? Number.parseFloat(netAmount): 0,
            paymentType: paymentType,
            currency: currency,
            date: date,
            userId: this.props.dataValues.userId,
            organisationId: this.props.dataValues.organisationId,
            categoryId: this.props.dataValues.categoryId,
            description: this.props.dataValues.description,
            observations: this.props.dataValues.observations,
            source: this.props.dataValues.source,
            receptionDate: this.props.dataValues.receptionDate,
            senderEmail: this.props.dataValues.senderEmail,
            emailObject: this.props.dataValues.emailObject,
            attachment: this.props.dataValues.attachment,
            createdAt: this.props.dataValues.createdAt,
        }
        
        updateInvoices(invoiceToUpdate)
            .then((updatedInvoice) => {
                this.props.updateInvoice(updatedInvoice);
                this.resetData();
                this.props.onDataUpdated();        
                this.displayNotification("Modification effectuée avec succès", "success");

            })
            .catch((err) => {
                console.error(err)
                this.setState({ loading: false });
                setTimeout(() => {
                    this.displayNotification( 'Echec de la modification, veuillez reéssayer ultérieurement !', 'error');
                }, 1000)
            })
    }
    getCurrency = () => {
        let currencies = [];
        Countries.countries.all.map((item) => {
            if(item.currencies[0] !== undefined){
                currencies.push(item.currencies[0])
            }
            return currencies;
        })
        currencies = arrayUniq(currencies);
        let sort = new SortingData();
        let curr = sort.alphabeticSort(currencies)

        return (
            curr.map((item, index) => {
                return (<option value={item} key={index}> {item}  </option>)
            })
        )
    }
    selectDefaultCurrency = (item) => {
        const {currency} = this.state;

        if(currency === item){
            return true;
        }
        return false;
    }

    handleChangeCurrency = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render(){
        const { loading } = this.state;
        const { description } = this.state;
        const { value, suggestions } = this.state;
        const inputProps = { placeholder: "Search a category", value, onChange: this.onChange }
       
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6">
                        <div className="container-fluid">
                            <div className="form-row">
                                <div className="col">
                                    <div className="container-logo">
                                        <div className="img-logo">
                                            <img src={logoUser} width={50} height={50} alt="Organization logo"/>
                                        </div>
                                        <div className="title-status">
                                            <span>Mboa Digital</span>
                                            <span>ENTREPRISE INDIVIDUELLE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-data'>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Date</label>
                                        <div className="container-datepicker">
                                            <DatePicker className='form-control input-date' autoFocus={false} value={new Date(this.state.date)} calendarIcon={null} clearIcon={null} locale='fr-FR' onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <label>Type paiement</label>
                                        <input type="text" name="paymentType" className="form-control inputCash" value={this.state.paymentType || ''} onChange={this.dataChange.bind(this)} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-sm-12">
                                        <select name="currency" id="currency" className="currency form-control" defaultValue={this.state.currency} onChange={this.handleChangeCurrency}>
                                            <option value="" >Veillez selectionner une devise</option>
                                            { this.getCurrency() }
                                        </select>
                                    </div>
                                </div>
                                <div></div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <span>Description</span> */}
                                        <div className="ct-tag"> <Tag icon={iconTag} string={description} /> </div>
                                    </div>
                                   
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Catégorie</label>
                                        <Autosuggest 
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps} 
                                            theme={CategoryTheme}
                                        />
                                    </div> 
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6" style={{paddingRight: 20}}>
                                        <label>Fournisseur</label>
                                        <input type="text" name="deliver" className="form-control" id="inputProvider" value={this.state.deliver || ''} onChange={this.dataChange.bind(this)} />
                                    </div>
                                    <div className="form-group col-md-6" style={{paddingLeft: 20}}>
                                        <label>NUI (Numero de contribuable)</label>
                                        <input type="text" name="nui" className="form-control" id="inputNUI" value={this.state.nui || ''} onChange={this.dataChange.bind(this)} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6" style={{paddingRight: 20}}>
                                        <label>Montant HT</label>
                                        <input type="number" placeholder='0' step='0.01' name="netAmount" className="form-control" id="inputTotalAccount" value={this.state.netAmount ? this.state.netAmount : 0} onChange={this.onChangeNetAmmount} />
                                    </div>
                                    <div className="form-group col-md-6" style={{paddingLeft: 20}}>
                                        <label>TVA</label>
                                        <input type="number" placeholder='0' step='0.01' name="vat" className="form-control" id="inputTVA" value={this.state.vat ? this.state.vat : 0} onChange={this.onChangeVat} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Montant TTC</label>
                                        <input type="number" step='0.01' name="totalAmount" className="form-control" id="inputTotalTVA" value={this.state.totalAmount ? this.state.totalAmount : 0} readOnly />
                                    </div>
                                </div> 
                                <div className="form-row mt-20">
                                    <button onClick={(e) => this.postData(e)} className="btn-submit">
                                        <div>
                                            <img src={iconSave} width={15} height={15} alt=""/>
                                            <span>Valider</span>
                                        </div>
                                    </button>
                                    {
                                        _canAccess(FUNCTIONALITIES_CODES.DELETE_INVOICE) ?
                                        <button onClick={this.deleteInvoice} className="btn-cancel">
                                            <div>
                                                <img src={iconTrash} width={18} height={18} alt=""/>
                                                <span>Supprimer</span>
                                            </div>
                                        </button> : null
                                    }
                                    {/* <button>log component state</button> */}
                                    <div className="loader">
                                        <div>
                                            {loading && (
                                                <Loader type="Oval" color="#177BD1" height="25" width="25" /> 
                                            )}
                                        </div>
                                        <div>
                                            {loading && <span>Sauvegarde en cours</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.attachment ? (this.state.contentType === ContentTypes.pdf) ?
                        <div className='col-6'>
                            <object data={this.state.attachment} width='100%' height='100%' type="application/pdf">
                            </object>
                        </div> 
                        :
                        <div className="container-img-invoice col-6">    
                            <div className="container-fluid">
                                {
                                    <ReactPanZoom key={this.state.id} image={this.state.attachment} alt="document image"/>
                                }
                            </div>
                        </div> :
                        <div className='col-6'>
                            <div className='flex-center'>
                                <Loader type="Oval" color="#177BD1" height="25" width="25" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default connect(null, {updateInvoice, deleteInvoice})(AdminPreview)

