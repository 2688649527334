import React, {Component} from 'react';
import MainLayout from '../../layouts/main';

import './Fees.css';

class Fees extends Component {
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){

        return (
            <MainLayout >
                <div>
                    Hello Fees!!!
                </div>
            </MainLayout>
        )
    }
}

export default Fees