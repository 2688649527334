import React, {Component} from 'react'

import NavItems from '../../Constants/NavItems'
import NavItem from '../../Components/Nav/NavItem'

import './NavLink.css'

class NavLink extends Component {
    constructor(props){
        super(props)

        this.state = {}
    }

    handleClick = (key) => {
        //this.props.history.navigate('/fees')
    }

    render(){
        const items = NavItems
        return (
            <div className="NavLink">
                {
                    items.map((item, index) => (
                        <NavItem data={item} key={index} index={index} onClick={this.handleClick} />
                    ))
                }
            </div>
        )
    }
}

export default NavLink
