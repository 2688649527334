import React, {Component} from 'react'

import './Footer.css'
class Footer extends Component {
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){

        return (
            <div className="container-footer">
                <span className="footer">Copyright © 2019. Tous droits réservés.</span>
            </div>
        )
    }
}

export default Footer