import App from "../App";
import BacklogPage from "../pages/backlog";
import Fees from "../pages/Fees";

export const ROUTES = [
    {
        path: "/",
        exact: true,
        protected: false,
        component: App,
    },
    {
        path: "/backlog",
        exact: true,
        protected: true,
        component: BacklogPage,
    },
    {
        path: "/fees",
        exact: true,
        protected: true,
        component: Fees,
    }
];