/**
 * To Format a number
 * @param {Number} numberValue the number's value to format
 * @param {Number} decimals the number of decimal at the decimal [optional]
 * @param {String} dec_point the decimal point [optional]
 * @param {String} thousands_sep the thousands separator [optional]
 * @returns {String} Number formated
 */
export const numberFormat = (numberValue, decimals, dec_point) => {
    numberValue =  numberValue * 1;
    decimals = typeof decimals !== 'undefined' ? decimals : 2;
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';

    var parts = Number.parseFloat(numberValue).toFixed(decimals).toString().split('.');

    return +parts.join(dec_point);
}