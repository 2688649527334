import axios from 'axios';
import axiosDefaultClient from '../config/axios';
import qs from 'querystring';
import AuthHelper from '../helpers/auth';
import { _extractPayloadFromToken } from '../helpers/jwt';
import {SESSION_STORAGE_KEYS} from '../Constants/Constants'

// baseURL: process.env.REACT_APP_API_BASE_URL + "/users"
const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL + "/auth"
})

export const _authenticate = async (credentials) => {
    let endpoint = `/login`;
    const requestBody = {
        username: credentials.username,
        password: credentials.password,
    }
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Client-Name': 'dizen/admin'
        }
    }
    let result = await defaultInstance.post(endpoint, qs.stringify(requestBody), config);
    return result.data;
};

export const _logout = async () => {
    const result = await defaultInstance.post(`/logout`, { token: AuthHelper._getTokens().access_token});
    sessionStorage.clear();
    return result.data;
};

export const _checkAuth = () => {
    try {
        const tokens =  AuthHelper._getTokens();
        if (!tokens) {
            return false;
        }
        if(!AuthHelper._isAdmin()){
            return false;
        }
        const tokenPayload = _extractPayloadFromToken(tokens.access_token);
        const { exp } = tokenPayload; // get the expiration time of the token
        if (exp < (new Date().getTime() / 1000)) {
            return false;
        }
    } catch (err) {
       console.error("can't verify token '", err)
        return false;
    }
    return true;
};

export const _getRole = async () => {
    const access_token = AuthHelper._getUser();
    if (access_token.sub) {
        let endpoint = `users/${access_token.sub}/role/0`;
        const role = (await axiosDefaultClient.get(endpoint)).data;
        if(role && role.length > 0) sessionStorage.setItem(SESSION_STORAGE_KEYS.ROLE, JSON.stringify(role[0]));
        return {data: role};
    }
    return {data: null};
}

export const _initFunctionalities = async (forceRefresh = false) => {
    const access_token = AuthHelper._getUser();
    if (access_token.sub) {
        let endpoint = `users/${access_token.sub}/functionalities/0`;
        let result = await axiosDefaultClient.get(endpoint);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.HABILITATIONS, JSON.stringify(result.data));
        await _getRole();
    }
}

export const _getFunctionalities = () => {
    const habilitations = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.HABILITATIONS));
    if (habilitations && habilitations.length > 0) {
        return habilitations;
    }
    return null;
}

export const _canAccess =  (fonctionality) => {
    const functionalities = _getFunctionalities();
    if (!functionalities) {
        return false;
    };

    const result = functionalities.filter((item) => {
        return item.functionalityCode === fonctionality
    });
    return result && result.length > 0;
}