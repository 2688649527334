import React, {Component} from 'react'
import PropTypes from 'prop-types'

import './TriItem.css'
class TriItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            triItemsImage: this.props.data.triItemsImage,
            triItemsLabel: this.props.data.triItemsLabel,
            triItemsActiveClass: this.props.data.triItemsActiveClass,
            triItemsActive: this.props.data.triItemsActive,
            index: this.props.index,
        }

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = () => {
        const {index} = this.state
        const {onClick} = this.props
        
        onClick(index)
    }

    render(){
        const {triItemsImage,triItemsActiveClass,triItemsActive,triItemsLabel} = this.state
        return (
            <button className={'example-custom-input ' + (triItemsActive) ? 'TriItem ' + triItemsActiveClass : 'TriItem '} onClick={this.handleClick} >
                <img src={triItemsImage} alt={triItemsLabel} width={25} height={25}/>
            </button>   
        )
    }
}

TriItem.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default TriItem