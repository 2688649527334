import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setInvoices } from '../../store/actions/invoicesActions';
import MainLayout from '../../layouts/main';
import TitleFeature from '../../Components/TitleFeature';
import TriSearch from '../../Components/TriSearch';
import TableData from '../../Components/TableData';
import Pagination from '../../Components/Pagination';
import Footer from '../../views/Footer';
import ModalData from '../../Components/ModalData';
import {searchInvoice} from '../../services/invoice.service';
import { _initFunctionalities } from '../../services/auth.service';
import './backlog.css';

class BacklogPage extends Component{
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageOfItems: [],
            pageSize: 10,
            invoices: [],
            filteredDataBacklog: [],
            filteredDate: [],
            filteredDataPending: [],
            filteredDataTreated: {
                checked: false,
                datas: []
            },
            searchTerm: '',
            selectedItem: null,
            open: false,
        };

        // bind function in constructor instead of render (https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md)
        this.onChangePage = this.onChangePage.bind(this);

        this.handleChangePageSize = this.handleChangePageSize.bind(this);

        this.handleTri = this.handleTri.bind(this);

        this.onOpenModal = this.onOpenModal.bind(this);

        this.onCloseModal = this.onCloseModal.bind(this);
        
    }

    async getData(){
        try {
            const [invoices, error] = await searchInvoice('');
            if(error || !invoices) {
                console.error(error);
                return;
            }
            this.loadInvoices(invoices.rows)
        } catch (error) {
            console.error(error);
        }
    }

    loadInvoices(data){
        try {
            const invoices = data.map((item) => {
                return {...item,
                    createdAt: moment(item.createdAt).format('YYYY/MM/DD'),
                    organizationCountry : item.organization ? item.organization.country : ''
                };
            })

            this.props.setInvoices(invoices);
            this.setState({ 
                filteredDataBacklog: this.props.invoices,
                loading: false,
            })
        } catch (error) {
            console.error(error);
        }
    }
    componentDidMount = async () => {
        await _initFunctionalities();
        this.getData();
    }

    onChangePage(pageOfItems) {
        // update state with new page of items
        this.setState({ pageOfItems: pageOfItems });
    }

    handleSearch = async(term) => {
        this.setState({
            searchTerm: term
        })
        if (term) {
            let [invoices, error] = await searchInvoice(term)
            if(error || !invoices) {
                console.error(error);
                return;
            }
            this.loadInvoices(invoices)
        } else this.getData()
    }

    handleTri = (key) => {
        let { invoices } = this.props.invoices;
        switch (key){
            case 0 :{
                let filterResult = invoices.reverse()
                this.setState({
                    pageOfItems: filterResult
                })
                break;
            }
            case 1 :{
                let filterResult = invoices
                const {filteredDataTreated} = this.state
                if(filteredDataTreated.checked === false){
                    this.setState({
                        filteredDataTreated: {
                            checked: true,
                            datas: [...invoices]
                        }
                    })

                    let results = filterResult.filter(item => 
                    item.status === "archived" || item.status === "closed"
                    )
                    
                    this.setState({
                        pageOfItems: results
                    },() => {
                        // console.log('results: ',results)
                    })

                }else{
                    let results = this.state.filteredDataTreated.datas

                    this.setState({
                        pageOfItems: results,
                        filteredDataTreated: {
                            checked: false,
                        }
                    },() => {
                        // console.log('results: ',results)
                    })
                }

                break;
            }
            case 2 :{
                let filterResult = invoices
                let results = filterResult.filter(item => 
                    item.status === "pending"
                )
                
                this.setState({
                    pageOfItems: results
                },() => {
                    // console.log('results: ',results)
                })
                break;
            }
            default:{
                break;
            }
        }
    }

    handleChangePageSize = (value) => {
        value = +value
        
        this.setState({
            ...this.state,
            pageSize: value
        }, () => {
            let { invoices } = this.props.invoices
            this.onChangePage(invoices);
        })
    }

    getPageSize = () => {
        const {pageSize} = this.state
        return pageSize
    }

    onOpenModal = () => {
        this.setState({
            open: true
        })
    };
     
    onCloseModal = () => {
        this.setState({ open: false });
    };

    onItemSelected = (data) => {
        this.setState({
            selectedItem: data,
            open: true

        })
    }

    render(){
        const {pageOfItems, pageSize, searchTerm, selectedItem} = this.state
        return(
            <MainLayout >
                <div className="backlog">
                    <div className="headerContent">
                        <TitleFeature title={'Backlog'} />
                        <TriSearch handleSearch={this.handleSearch} handleTri={this.handleTri} term={searchTerm}/>
                    </div>
                    
                    <ModalData open={this.state.open} onCloseModal={this.onCloseModal} data={selectedItem}/>
                    <TableData loading={this.state.loading} className='mt-20' datas={pageOfItems} onItemSelected={this.onItemSelected} />
                    <Pagination items={this.props.invoices} onChangePage={this.onChangePage} initialPage={1} pageSize={pageSize} onChangePageSize={this.handleChangePageSize} /> 
                    <Footer/>
                </div>
            </MainLayout>
                
        )
    }
}

const mapStateToProps = (state) => {
    return {
        invoices: state.invoices.invoices,
    }
}

export default connect(mapStateToProps, {setInvoices})(BacklogPage)