import React, {Component} from 'react'
import Modal from 'react-responsive-modal'
import AdminPreview from '../AdminPreview'
import './ModalData.css'

class ModalData extends Component {
    constructor(props, context){
        super(props, context);
        
        this.state = {
            open: false,
            data: this.props.data,
        };
        this.onCloseModal = this.onCloseModal.bind(this)
    }
   
    onCloseModal = () => {
        this.props.onCloseModal()
    };

    onDataUpdated = () => {
        this.props.onCloseModal()
    }

    render(){
        const { open, data} = this.props;
        return (
            <Modal open={open} onClose={this.onCloseModal} >
                <AdminPreview dataValues={data} onDataUpdated={this.onDataUpdated}/>
            </Modal>
        )
    }
}

export default ModalData