import axios from './axios';

export default {
  setupInterceptors: (store) => {
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
            const authToken = JSON.parse(sessionStorage.getItem("token"));
            if(authToken !== null) {
                const { access_token } = authToken
                if ( access_token !== null ) {
                    config.headers.Authorization = `Bearer ${access_token}`;
                    config.headers["X-Client-Name"] = 'dizen/admin';
                }
            }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
  }
};