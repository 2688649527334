import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../views/Header';
import NavLink from '../views/NavLink';
import MainContent from '../views/MainContent';
import { setAuthToken } from '../store/actions/AuthAction';

import '../views/Main/Main.css';

class MainLayout extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    handleClick = (e) => { }

    render() {

        return (
            <div className="main">
                <Header />
                <NavLink onClick={this.handleClick} />
                <MainContent className={'pr-20'}>
                    {this.props.children}
                </MainContent>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        authToken: auth.authToken,
    }
}

export default connect(mapStateToProps, { setAuthToken })(MainLayout)
