import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Dropdown,DropdownButton} from 'react-bootstrap'

import iconFirstPage from '../../assets/svg/icon-first-page.svg'
import iconPreviouspage from '../../assets/svg/icon-previous.svg'
import iconNextPage from '../../assets/svg/icon-next.svg'
import iconLastPage from '../../assets/svg/icon-last-page.svg'

import './Pagination.css'

const propTypes = {
    items: PropTypes.array,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number.isRequired,
    handleChangePageSize: PropTypes.func,
}

const defaultProps = {
    initialPage: 1,
    pageSize: 10
}

class PaginationData extends Component {
    constructor(props){
        super(props)

        this.state={
            pager: {}
        }
    }

    UNSAFE_componentWillMount() {
        // set page if items array isn't empty
        if (this.props.items && this.props.items.length) {
            this.setPage(this.props.initialPage);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            this.setPage(this.props.initialPage);
        }
    }

    setPage = (page) => {
        var { items, pageSize } = this.props;
        var pager = this.state.pager;

        // calculate total pages
        pager.totalPages = Math.ceil(items.length / pageSize);
        if ((page < 1) || (page > pager.totalPages && items.length !== 0)) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(items.length, page, pageSize);

        // get new page of items from items array
        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({ pager: pager });

        // call change page function in parent component
        this.props.onChangePage(pageOfItems);
    }

    getPager = (totalItems, currentPage, pageSize) => {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    getActiveClass = (page) => {
        var pager = this.state.pager;
        if (pager.currentPage === page) {
            return 'onePage active'
        } 
        return 'onePage'
    }

    handleSelectRange = (value) => {
        const {onChangePageSize} = this.props
        onChangePageSize(value)
        this.setState({
            pager: {...this.state.pager, pageSize: value}
        })
        
    }
   
    render(){
        var pager = this.state.pager;

        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }
        return (
            <div className="container-pagination">
                <div className="pagination-left">
                    <div className="bt-left">
                        <button className={pager.currentPage === 1 ? 'disabled' : ''} onClick={() => this.setPage(1)}><img src={iconFirstPage} alt="First-page" width={25} height={25} title="First Page"/></button>
                        <button className={pager.currentPage === 1 ? 'disabled' : ''} onClick={() => this.setPage(pager.currentPage - 1)}><img src={iconPreviouspage} alt="Previous" title="Previous" width={25} height={25}/></button>
                    </div>
                    <div className="bt-center ml-15">
                        {pager.pages.map((page, index) =>
                            <button key={index} className={this.getActiveClass(page)} onClick={() => this.setPage(page)} >
                                <span>{page}</span>
                            </button>
                         )}
                    </div>
                    <div className="bt-right">
                        <button className={pager.currentPage === pager.totalPages ? 'disabled' : ''} onClick={() => this.setPage(pager.currentPage + 1)}><img src={iconNextPage} alt="Next" width={25} height={25}/></button>
                        <button className={pager.currentPage === pager.totalPages ? 'disabled' : ''} onClick={() => this.setPage(pager.totalPages)}><img src={iconLastPage} alt="Last-page" width={25} height={25}/></button>
                    </div>
                </div>
                
                <div className="pagination-rigth">
                    <span width={100} className="page-range">{pager.startIndex + 1} - {pager.endIndex + 1} of {pager.totalItems}</span>
                    <DropdownButton id="dropdown-basic-button" title={pager.pageSize} variant={'none'}>
                        <Dropdown.Item href="/#" onSelect={this.handleSelectRange.bind(this, 5)} >{5}</Dropdown.Item>
                        <Dropdown.Item href="/#" onSelect={this.handleSelectRange.bind(this, 10)} >{10}</Dropdown.Item>
                        <Dropdown.Item href="/#" onSelect={this.handleSelectRange.bind(this, 20)} >{20}</Dropdown.Item>
                        <Dropdown.Item href="/#" onSelect={this.handleSelectRange.bind(this, 30)} >{30}</Dropdown.Item>
                        <Dropdown.Item href="/#" onSelect={this.handleSelectRange.bind(this, 50)} >{50}</Dropdown.Item>
                        <Dropdown.Item href="/#" onSelect={this.handleSelectRange.bind(this, 100)} >{100}</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
        )
    }
}

PaginationData.propTypes = propTypes;
PaginationData.defaultProps = defaultProps;
export default PaginationData