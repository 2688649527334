import { _extractPayloadFromToken } from './jwt';
import {SESSION_STORAGE_KEYS,ROLES} from '../Constants/Constants'

export default class AuthHelper {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    static _getUser(){
        return  _extractPayloadFromToken(this._getTokens().id_token);
     }
 
     static _getTokens(){
         const tokenJson = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
         if(!tokenJson){
            return undefined;
         }
         return JSON.parse(tokenJson);
     }

     static _isAdmin(){
         return AuthHelper._getUser()['custom:roles'] === ROLES.ADMIN
     }
   
}
