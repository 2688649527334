// import dashboard from '../assets/svg/icon-dashboard.svg'
// import backlog from '../assets/svg/icon-albums.svg'
import fees from '../assets/svg/icon-fees.svg'

const ItemsCategory = [
    {
        id: '1',
        title: 'Bank',
        image: 'bank'
        
    },
    {
        id: '2',
        title: 'Fees',
        image: 'fees',
        images: fees
    },
    {
        id: '3',
        title: 'Transport',
        image: 'transport'
    },
    {
        id: '6',
        title: 'Billing',
        image: 'billing'
    },
    {
        id: '4',
        title: 'Social',
        image: 'social'
    },
    {
        id: '5',
        title: 'Treasury',
        image: 'treasury'
    }
]

export default ItemsCategory