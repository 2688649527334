import dashboard from '../assets/svg/icon-dashboard.svg'
import backlog from '../assets/svg/icon-albums.svg'
import userAccount from '../assets/svg/icon-account.svg'

const NavItems = [
    {
        image: dashboard,
        label: 'Tableau de bord',
        route: '/dashboard',
        activClass: 'active',
        active: false
    },
    {
        image: backlog,
        label: 'Backlog',
        route: '/backlog',
        activClass: 'active',
        active: true
    },
    {
        image: userAccount,
        label: 'Comptes',
        route: '/compte',
        activClass: 'active',
        active: false
    },
]

export default NavItems