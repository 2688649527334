const CategoryTheme = {
    input:{
        display: 'block',
        lineHeight: 1.5,
        padding: '.375rem .75rem',
        backgroundColor: '#fff',
        width: '100%',
        backgroundClip: 'padding-box',
        borderRadius: '.25rem'
    },
    suggestionsContainerOpen:{
        display: 'block',
        position: 'absolute',
        width: '100%',
        border: '1px solid #aaa',
        backgroundColor: '#fff',
        fontWeight: 300,
        fontSize: '16px',
        zIndex: 2
    },
    container:{
        position: 'relative'
    },
    inputFocused:{
        outline: 'none'
    },
    suggestionsContainer:{
        display: 'none'
    },
    suggestionsList:{
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    },
    suggestion:{
        cursor: 'pointer',
        padding: '10px 10px'
    },
    suggestionHighlighted:{
        backgroundColor: 'rgba(32, 182, 248, 0.1)'
    }
}

export default CategoryTheme