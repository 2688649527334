import React, {Component} from 'react'
import {formatDate} from '../../helpers/dateHelper'
import './DateItemList.css'
class DateItemList extends Component {

    constructor(props){
        super(props)

        this.state = {
            date: this.props.date,
            active: this.props.active,
        }
    }

    datePart = (date) => {
        const day = formatDate(date, 'DD');
        const month = formatDate(date, 'MMM').replace(".", "");
        const year = formatDate(date, 'YY');
        return {year, day, month};
    }

    render(){
        const {date,active} = this.props
        
        const part = this.datePart(date)
        const day = part ? part.day : ''
        const year = part.year
        const month = part ? part.month : ''

        return(
            <div className={ (active) ? 'DateItemList active' : 'DateItemList'}>
                <div className={ (active) ? 'boxDayactive' : 'boxDay'}>
                    <span className="txtDay">
                        {day}
                    </span>
                </div>
                <div className={(active) ? 'boxMonthactive' : 'boxMonth'}>
                    <span className="txtMonth">
                        {month} {year}
                    </span>
                </div>
            </div>
        )
    }

} 



export default DateItemList

