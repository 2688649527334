import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TriItems from '../../Constants/TriItems'
import TriItem from '../Tri/TriItem'
import Search from '../Search'

import './TriSearch.css'

class Trisearch extends Component {
    constructor(props){
        super(props)
        this.state = {
            className: this.props.className
        }
    }

    handleClick = (key) => {
        let {handleTri} = this.props
        if (key === 0 ) {
            console.log('Date: ', key)
            handleTri(key)
        }if (key === 1) {
            console.log('Archieve: ', key)
            handleTri(key)
        }if (key === 2) {
            console.log('Pending: ', key)
            handleTri(key)
        }
        
    }

    handleSearch = (term) => {
        this.props.handleSearch(term)
    }

    render(){
        const {className} = this.state
        const items = TriItems
        return (
            <div className={'tri-search ' + className}>
                <div className="content-left">
                    <span className="title-tri">Trier par:</span>
                        {
                            items.map((item, index) => (
                                <TriItem data={item} key={index} index={index} onClick={this.handleClick} />
                            ))
                        }
                </div>
                <Search handleSearch={this.handleSearch} term={this.props.term}/>
            </div>
        )
    }
}

Trisearch.propTypes = {
    Trisearch: PropTypes.func
}

export default Trisearch