import React, {Component} from 'react'

import './Header.css'
import userIonicon from '../../assets/svg/user-circled-ionicon.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import AuthHelper from '../../helpers/auth'

class Header extends Component {
    constructor(props){
        super(props)
        this.state = {}
    }

    render(){

        return (
            <div className="header">
                  <div className="logo">
                    <span className="bloc"><span className="bold">Di</span>Zen</span>
                  </div>
                <div className="right">
                  <div className="noti">
                    <span className="nb-noti">5</span>
                  </div>

                  <div className="usr-block">
                    <img src={userIonicon} alt="user icon" width={25} height={25}/>
        <span className="label-user">{AuthHelper._getUser().given_name} {AuthHelper._getUser().family_name}</span>
                    <img src={arrowDown} alt="arrow down"/>
                  </div>
                </div>
            </div>
        )
    }
}

export default Header