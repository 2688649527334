import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthPage from './pages/Auth/Auth';
import { setAuthToken } from './store/actions/AuthAction';
import './App.css';

class App extends Component {

  render() {
    return (
      <div className="App">
      {
        <AuthPage/>
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	const { auth } = state
	return {
        authToken: auth.authToken
	}
}
export default connect(mapStateToProps, { setAuthToken })(App);
