import date from '../assets/svg/icon-calendar.svg'
import traiter from '../assets/svg/icon-classer.svg'
import nonTraiter from '../assets/svg/icon-nonclasser.svg'

const TriItems = [
    {
        triItemsImage: date,
        triItemsLabel: 'icon-date',
        triItemsActiveClass: 'icon-date',
        triItemsActive: true
    },
    {
        triItemsImage: traiter,
        triItemsLabel: 'icon-classer',
        triItemsActiveClass: 'icon-archived',
        triItemsActive: true
    },
    {
        triItemsImage: nonTraiter,
        triItemsLabel: 'icon-nonclasser',
        triItemsActiveClass: 'icon-pending',
        triItemsActive: true
    },
]

export default TriItems