import axios from 'axios'

let baseURL = process.env.REACT_APP_API_BASE_URL + "/admin/";
//  let baseURL = 'http://localhost:8000';

const instance = axios.create({
    baseURL,
    headers: {
        'X-Client-Name': 'dizen/admin'
    }
})

export default instance