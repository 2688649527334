import axios from '../config/axios';

const INVOICE_BASE_URL = 'invoices/';

export const getInvoiceById = async (id)=> {
    try {
        const invoice = await axios.get(INVOICE_BASE_URL+id);
        return invoice.data;
    } catch(err) {
        console.error('Error', err);
        return null;
    }
}

export const updateInvoices = async (invoice) => {
        return new Promise((resolve,reject) => {
            axios.put(INVOICE_BASE_URL+invoice.id, invoice)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

export const getInvoice = async () => {
    return new Promise((resolve,reject) => {
        axios.get(INVOICE_BASE_URL).then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const searchInvoice = async (filter) => {
    return new Promise((resolve,reject) => {
        const param = {
            params: {
                filter: filter
            }
        }
        axios.get(INVOICE_BASE_URL, param).then((res) => {
            if(res.status === 200 && res.data)
                resolve([res.data, null]);
            else 
                resolve([null, 'Error when get invoices']);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const _deleteInvoice = async (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(INVOICE_BASE_URL + id).then((res) => {
            if(res.status === 200 && res.data) resolve([res.data, null]);
            else resolve([null, 'Error when delete invoice']);
        }).catch((err) => {
            reject(err);
        })
    });
}
